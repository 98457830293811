.confirmation_container {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

.confirmation_container > h1,
.confirmation_container > h2 {
  color: #939393;
  text-align: center;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main_container {
  min-height: 100vh;
  padding-bottom: 10vh;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  gap: 45px;
}

.upcoming_container {
}

.upcoming_container > h1 {
  padding-left: 45px;
  color: #28cf60;
  text-align: start;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upcoming_carousel {
  padding: 0px 45px;

  width: 100vw;

  display: flex;
  flex-direction: row;

  gap: 20px;
  margin: 20px 0px;

  flex-wrap: nowrap;

  overflow-x: scroll;
}

.upcoming_carousel > .carousel_card_item {
  width: 300px !important;
  height: 130px;
}

.upcoming_carousel > .carousel_card_item .name_container {
  color: #fff;
  text-align: start;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upcoming_carousel > .carousel_card_item .host_container {
  color: #939393;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.carousel_card_item {
  width: 300px;
  height: 300px;

  padding: 18px;

  border-radius: 20px;
  background-image: url("https://i.ibb.co/HtRrwPV/bg.png");

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: stretch;
}

.top_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  gap: 32px;
}

.bottom_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 32px;
}

.name_container {
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.host_container {
  color: #939393;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.participants {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant_icon {
  width: 15px;
  height: 15px;

  border-radius: 50%;

  position: relative;
}

.participant_count {
  color: #fff;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-left: 4px;
}

.date_box {
  width: 60px;
  height: 60px;

  border-radius: 10px;
  background: #fffa;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.35);

  display: grid;
  place-items: center;

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register_button {
  width: 81px;
  height: 20px;

  border-radius: 10px;
  background: #fffa;

  color: #fff;
  text-align: center;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: grid;
  place-items: center;
}

.middle_row {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon_button {
  background: none;
  border: none;
}
