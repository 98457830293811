.button_container {
  min-width: 167px;
  height: 50px;

  border: 0px solid #000;
  border-radius: 30px;
  background: #ee8802;

  padding: 0px 20px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button_container:disabled,
.button_container[disabled] {
  background-color: #cccccc;
  color: #666666;
}
