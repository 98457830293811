.main_container {
  width: 314px;
  height: 69px;

  border-radius: 30px;
  background: #fff;

  color: #2ed365;
  font-size: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: center;

  border: none;
}

.main_container::placeholder {
  color: #2ed365;
  font-size: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: center;
  opacity: 0.5;
}
