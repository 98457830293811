.mainContainer {
  position: relative;
  width: 150px;
  margin-bottom: 10px;
}

.dropdownContainer {
  position: relative;
  width: 100%;
  display: inline-block;
}

.inputContainer {
  width: 100%;
  height: 50px;
  background: #ea6c05;
  border: 2px solid #f6faf4; /* Example border */
  color: #f16868;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-radius: 15px;
  cursor: pointer;
}

.optionsContainer {
  width: 100%;
  background-color: #f9f5f5;
  border: 1px solid #fbfdfa;
  position: absolute;
  z-index: 1000;
  box-shadow: 0 ;
  overflow-y: auto;
}

.optionContainer {
  width: 100%;
  height: 50px;
  border: none;
  background: linear-gradient(to right, #fff, #e9ebea); 
  color: #e9ebea;
  text-align: left;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 15px;
}

.optionContainer:hover {
  background-color: #f0f0f0;
}

.icon {
  width: 20px;
  height: 20px;
}
