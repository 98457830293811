/* Base styles for both mobile and desktop */
.navbar {
  z-index: 2;
  position: fixed;
  width: 100vw;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sidebar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%; /* Hide the sidebar off-screen by default */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  gap: 64px;
  background-color: #2ed365;
}

/* Add this CSS for positioning the hamburger menu on the right */
.hamburger_icon_container {
  z-index: 3;
  border-radius: 4px;
  outline: none;
  border: none;
  position: absolute;
  right: 0px; /* Adjust the right position as needed */
  top: 0px; /* Adjust the top position as needed */
}

.nav_link {
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  color: #fff;
  text-align: center;
  font-family: Philosopher;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Media queries for mobile responsiveness */
@media (max-width: 767px) {
  .sidebar {
    left: 0; /* Show the sidebar on the left for mobile */
    padding: 20px; /* Adjust padding for mobile */
  }
}

/* Media queries for desktop responsiveness */
@media (min-width: 768px) {
  .sidebar {
    padding: 160px 0; /* Restore padding for desktop */
  }
}
