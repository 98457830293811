.container {
  position: fixed;
  bottom: 0px;
  left: 0px;

  width: 100vw;

  background-color: #2ed365;

  padding: 0px 24px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.hamburger_icon_active {
  position: relative;

  bottom: 24px;
}

.hamburger_icon {
  padding: 12px;
}
