.carousel_card_item {
  width: 200px;
  height: 130px;
  flex-shrink: 0;

  padding: 14px;

  border-radius: 20px;
  box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: stretch;
}

.top_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
}

.bottom_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;
}

.name_container {
  color: #fff;
  text-align: left;
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.host_container {
  color: #939393;
  text-align: left;
  font-size: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.participants {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant_icon {
  width: 15px;
  height: 15px;

  border-radius: 50%;

  position: relative;
}

.participant_count {
  color: #fff;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-left: 4px;
}

.date_box {
  width: 40px;
  height: 40px;

  border-radius: 10px;
  background: #fffa;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.35);

  display: grid;
  place-items: center;

  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register_button {
  width: 60px;
  height: 16px;

  border-radius: 10px;
  background: #fffa;

  color: #fff;
  text-align: center;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: grid;
  place-items: center;
}
