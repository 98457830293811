.main_container {
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  background-position-x: center;
  background-position-y: top;
  background-size: 100vw auto;
  background-repeat: no-repeat;

  background-image: url("../../assets/splash-static.png");
}
