/* SocialFollow.module.css */
.section {
}

.socialContainer {
  background: #eee;
  padding: 25px 0; /* Remove horizontal padding to allow icons to be in one line */
  display: flex; /* Display the child elements in a column */
  flex-direction: column; /* Stack child elements vertically */
  text-align: center; /* Center-align text within the container */
}

.mediumTitleSpan1 {
  color: #2ed365;
  font: 700 1em/1.2 "Montserrat", Helvetica, Arial, serif;
}

.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

.social:hover {
  transform: translateY(-2px);
}

.youtube {
  color: #eb3223;
}

.facebook {
  color: #4968ad;
}

.twitter {
  color: #49a1eb;
}

.instagram {
  color: black;
}
