/* Your existing global styles */
body,
h1,
h2,
p {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
}

/* Header styles */
/* ... (your existing header styles) */

/* Main container styles (for the new section) */
.main_container {
  background-color: #2ed365;
  min-height: 100vh;
  padding-bottom: 10vh;
  background-size: cover;
  background-position: bottom;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

/* Styles for the images in the new section */
.image_top,
.image_bottom {
  max-width: 700px; /* Ensure images are responsive and fit within their containers */
  max-height: 400px;
  height: auto; /* Automatically adjust image height while maintaining aspect ratio */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Styles for the medium title in the new section */
.medium_title {
  color: #000000;
  font: 400 24px/1.2 "Montserrat", Helvetica, Arial, serif;
  letter-spacing: 0;
  text-align: center; /* Center text on all devices */
}

.medium_title_box {
  align-items: center;
  display: flex;
  min-height: 115px;
  /* You may need to adjust the positioning for different screen sizes */
  position: relative;
  width: 100%; /* Ensure the title spans the full width on all devices */
}

.medium_title_span0 {
  font: 1em/1.2 "Montserrat", Helvetica, Arial, serif;
}

.medium_title_span1 {
  color: #2ed365;
  font: 700 1em/1.2 "Montserrat", Helvetica, Arial, serif;
}

.medium_title_span2 {
  font: 1em/1.2 "Montserrat", Helvetica, Arial, serif;
}

/* Styles for the highlights and highlights_bottom in the new section */
.highlights,
.highlights_bottom {
  align-items: center;
  color: #000000;
  display: flex;
  font: 400 18px/1.22 "Montserrat", Helvetica, Arial, serif;
  /* Your existing styles for these elements */
  text-align: center; /* Center text on all devices */
}
