.main_container {
}

.participants_container {
  display: flex;

  flex-direction: column;

  padding: 32px;

  gap: 24px;
}

.participant_container {
  width: 315px;

  background-image: url("../../assets/user-participant-background.jpeg");
  background-position: center;

  border-radius: 20px;
}

.participant_container_bottom {
  width: 315px;

  background-image: url("../../assets/background-participant.jpeg");
  background-position: center;

  border-radius: 20px;
}

.top_container {
  padding: 24px;

  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 14px;
}

.avatar {
  width: 60px;
  height: 60px;
  flex-shrink: 0;

  border-radius: 60px;
}

.name {
  color: #30513e;
  font-family: Philosopher;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text {
  color: #4d4d4d;
  font-family: Philosopher;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.participants_header {
  color: #30513e;
  text-align: left;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  align-self: stretch;
}

.bottom_container {
  padding: 8px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Change from 'space-between' to 'center' */
}
