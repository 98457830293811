.main_container {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
  background-position: bottom;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: stretch;

  padding-bottom: 50px;

  gap: 20px;
}

.schedule_header {
  padding: 0px 28px;

  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: space-between;
}

.schedule_title {
  color: #30513e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.schedule_date {
  color: #939393;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.schedule_list {
  padding: 0px 16px;
}

.list_item {
  margin-top: 16px;

  display: flex;
  flex-direction: row;
}

.join_event_button {
  width: 96px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 15px;
  background: #30513e;

  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-left: 20px;
}
.participant_container_bottom {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 300px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #d60a0a;
  overflow: hidden; /* Hide overflowing progress bar */
}

/* Create a pseudo-element for the progress bar */
.participant_container_bottom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Start with full width */
  height: 10px; /* Set the height of the progress bar */
  background-color: green; /* Change to green */
  animation: progress 60s linear forwards; /* Animation duration, timing function, fill mode */
  z-index: -1; /* Behind the content */
}

/* Define the progress animation */
@keyframes progress {
  0% {
    width: 100%; /* Starting width */
  }
  100% {
    width: 0%; /* Ending width (fully filled) */
  }
}

/* Add a class to start the progress */
.participant_container_bottom.active::before {
  animation-play-state: running; /* Start the animation */
}

.participant_container_bottom:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.live_icon {
  width: 50px;
  height: 50px;
  background-color: #ff3c64;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px; /* Adjust this to your preference */
  right: -25px; /* Adjust this to your preference */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff3c64;
}

.action_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -25px; /* Adjust this to your preference */
  right: -25px; /* Adjust this to your preference */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #ff3c64;
}

.social_link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 100px; /* Adjust this to your preference */
  right: 25px; /* Adjust this to your preference */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
}

.live_icon img {
  width: 30px;
  height: 30px;
}

.avatar_container {
  flex: 0 0 auto;
  margin-right: 20px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 35%;
  object-fit: cover;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 2px solid #f9f9f9;
}

.avatar:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.details {
  flex: 1;
}

.name {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.text {
  font-size: 16px;
  color: #555;
  margin: 0;
}

.social_links {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.social_links img {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  transition: transform 0.2s ease-in-out;
}

.social_links img:hover {
  transform: scale(1.1);
}

.progress_bar {
  height: 5px;
  background-color: #ccc;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  width: 0;
  background-color: #007bff; /* Set your desired color */
  position: absolute;
  animation: fillAndWait 5s linear forwards; /* Add the animation */
}

@keyframes fillAndWait {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.content_container {
  position: relative;
}
