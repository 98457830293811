.main_container {
  min-height: 100vh;

  /* display: flex; */
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;

  padding-bottom: 50px;

  gap: 20px;
}
.centered-link-container {
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  height: 100vh; /* Sets the container's height to fill the viewport */
  width: 100vw; /* Sets the container's width to fill the viewport width */
  text-align: center; /* Ensures the text is centered, useful for inline or inline-block elements */
}

.card_container {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border: 1px solid #2ed365; /* Add a 1px solid border with the desired color */
  padding: 14px;
}

.top_container {
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  align-items: flex-start;
}

.card_image {
  width: 102px;
  height: 98px;
}

.card_details_section {
  width: 233px;
  height: 206px;

  background: #ffffff;
  opacity: 0.9;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  align-items: stretch;

  padding: 20px;
}

.card_details_horizontal {
  flex: 1;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.round_img {
  width: 60px;
  height: 60px;

  border-radius: 50%;
}

.card_footer {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.card_pill_button {
  width: 56.86px;
  height: 15.79px;

  background: #6ca943;
  border-radius: 10px;

  border: none;
  outline: none;

  font-family: "Biryani";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
}

.card_icons_container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.bottom_carousel {
  align-self: stretch;
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.carousel_card {
  width: 106px;

  background: #b2c990;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel_image {
  width: 90px;
  height: 119px;
}

.bottom_footer {
  padding: 6px;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.bottom_pill {
  font-family: "Biryani";
  font-style: normal;
  font-weight: 400;
  font-size: 5px;
  line-height: 9px;

  text-align: center;

  color: #ffffff;

  width: 36.53px;
  height: 9.21px;

  background: #6ca943;
  border-radius: 10px;

  outline: none;
  border: none;
}

.white_pill {
  padding: 4px;
  background: #ffffff;
  border-radius: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #2ed365;

  outline: none;
  border: none;

  min-width: 50px;
}

.details_text {
  font-family: "Philosopher";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;

  color: #ffffff;
}

.top_icon_container {
  align-self: stretch;
  padding: 0px 30px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.edit_btn {
  border: none;
  background: none;
  outline: none;
}

.upcoming_container {
}

.upcoming_container > h1 {
  padding-left: 45px;
  color: #28cf60;
  text-align: start;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upcoming_carousel {
  padding: 0px 45px;

  width: 100vw;

  display: flex;
  flex-direction: row;

  gap: 20px;
  margin: 20px 0px;

  flex-wrap: nowrap;

  overflow-x: scroll;
}

.upcoming_carousel > .carousel_card_item {
  width: 300px !important;
  height: 130px;
}

.upcoming_carousel > .carousel_card_item .name_container {
  color: #fff;
  text-align: start;
  font-size: 13px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upcoming_carousel > .carousel_card_item .host_container {
  color: #939393;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.carousel_card_item {
  width: 300px;
  height: 300px;

  padding: 18px;

  border-radius: 20px;
  background-image: url("https://i.ibb.co/HtRrwPV/bg.png");

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: stretch;
}

.top_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  gap: 32px;
}

.bottom_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  gap: 32px;
}

.name_container {
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.host_container {
  color: #939393;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.participants {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant_icon {
  width: 15px;
  height: 15px;

  border-radius: 50%;

  position: relative;
}

.participant_count {
  color: #fff;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-left: 4px;
}

.date_box {
  width: 60px;
  height: 60px;

  border-radius: 10px;
  background: #fffa;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.35);

  display: grid;
  place-items: center;

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register_button {
  width: 81px;
  height: 25px;

  border-radius: 10px;
  background: #fffa;

  color: #fff;
  text-align: center;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: grid;
  place-items: center;
}

.middle_row {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon_button {
  background: none;
  border: none;
}

.cta_button {
  display: inline-block;
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #2ed365;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.cta_button:hover {
  background-color: #2ed365;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
/* Update your ExplorePage.module.css */
.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.action_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  padding: 4px 4px 4px 8px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  color: #fff;
  font-size: 16px;
}

.schedule_button {
  background-color: #3498db;
}

.join_button {
  background-color: #27ae60;
}

.participants_button {
  background-color: #e74c3c;
}

.icon {
  font-size: 24px; /* Adjust the size as needed */
  margin-right: 10px;
}

.action_button:hover {
  background-color: #333;
}
