.main_container {
  width: 100vw;

  padding: 100px 0px 40px 0px;
}
.container {
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding-bottom: 70px;
}

.page_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  text-align: center;

  color: #2ed365;
}

.card_carousel {
  padding: 20px 0px;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 20px;
}

.carousel_card_item {
  width: 300px;
  height: 300px;

  padding: 18px;

  border-radius: 20px;
  background-image: url("https://i.ibb.co/HtRrwPV/bg.png");

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: stretch;
}

.top_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
}

.bottom_row {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;
}

.name_container {
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.host_container {
  color: #939393;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.participants {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.participant_icon {
  width: 15px;
  height: 15px;

  border-radius: 50%;

  position: relative;
}

.participant_count {
  color: #fff;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-left: 4px;
}

.date_box {
  width: 60px;
  height: 60px;

  border-radius: 10px;
  background: #2ed365aa;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.35);

  display: grid;
  place-items: center;

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.register_button {
  width: 81px;
  height: 25px;

  border-radius: 10px;
  background: #2ed365aa;

  color: #fff;
  text-align: center;
  font-size: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: grid;
  place-items: center;
}

.cta_button {
  display: inline-block;
  padding: 6px 8px;
  border-radius: 6px;
  background-color: #2ed365;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.cta_button:hover {
  background-color: #2ed365;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
