/* Common styles for both mobile and desktop */
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}
.container {
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding-bottom: 70px;
}

.header {
  color: #2ed365;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding: 0px 24px;
}

.page_title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #2ed365;
  margin-bottom: 20px;
}

.card_carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
}

.carousel_card_item {
  width: 300px;
  min-height: 300px;
  padding: 18px;
  border-radius: 20px;
  background-image: url("https://i.ibb.co/HtRrwPV/bg.png");
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  color: #fff;
  text-align: left;
}

.top_row,
.bottom_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.name_container {
  font-size: 20px;
  font-weight: 500;
}

.host_container {
  font-size: 14px;
  color: #939393;
}

.participants {
  display: flex;
  align-items: center;
}

.participant_icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 4px;
}

.participant_count {
  font-size: 12px;
  font-weight: 600;
}

.date_box {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background: #2ed365aa;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.register_button {
  width: 81px;
  height: 25px;
  border-radius: 10px;
  background: #2ed365aa;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .carousel_card_item {
    width: 100%;
  }

  .page_title {
    font-size: 20px;
  }

  .card_carousel {
    padding: 0;
  }

  .carousel_card_item {
    padding: 12px;
  }

  .name_container {
    font-size: 16px;
  }

  .host_container {
    font-size: 12px;
  }

  .participant_count {
    font-size: 10px;
  }
}
/* Add this CSS for card container */
.plusIcon {
  float: right;
  margin-right: 10px; /* Adjust the margin as needed */
}
