.main_container {
  background: #2ed365;
  min-height: 100vh;
  padding-bottom: 10vh;
  background-size: cover;
  background-position: bottom;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: center;

  gap: 20px;
}

.subtitle {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.terms_conditions {
  color: #fff;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;
}
