/* Use CSS variables for colors and common styles */
:root {
  --primary-color: #2ed365;
  --secondary-color: #ee8802;
  --text-color: #fff;
  --light-text-color: #d9d9d9;
  --dark-text-color: #333;
}

/* Reset default margin and padding for the entire page */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

/* Apply a background gradient for better aesthetics */
.main_container {
  min-height: 100vh;
  background: #2ed365;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 20px;
}

.title {
  color: var(--text-color);
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  color: #f8fdfa;
  text-align: center;
}

.logo {
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  width: 50px;
  max-width: initial;
  height: 63px;
  border: none;
  object-fit: cover;
  align-self: center;
}
.back_button {
  position: absolute;
  top: 19px;
  left: 18.5px;
  display: flex;
  width: 32px;
  height: 32px;
  color: rgba(46, 211, 101, 1);
}
/* Use more specific class names for improved styling */
.mobile-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 1px solid var(--dark-text-color);
  border-radius: 5px;
}

.button {
  min-width: 167px;
  height: 50px;
  border: 0;
  border-radius: 30px;
  background: var(--secondary-color);
  padding: 0 20px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  transition: background 0.3s;
}

.button:disabled,
.button[disabled] {
  background-color: #aaaaaa;
  color: #dddddd;
  cursor: not-allowed;
}
.resend_button {
  align-self: flex-start;
  margin-left: 35px;

  background: none;
  text-decoration: underline;
  color: #2ed365;
  border: none;
}

.terms_conditions {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #f8fdfa;
  align-self: center;
}

.hero_container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px 200px 200px;
  padding: 40px;
}

.hero {
  max-width: 100%;
  height: auto;
  width: auto;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .main_container {
  }
}

.bottom_button {
  min-width: 167px;
  height: 50px;

  border: 0px solid #000;
  border-radius: 30px;
  background: #ee8802;

  padding: 0px 20px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-self: center;
}

.bottom_button:disabled,
.bottom_button[disabled] {
  background-color: #aaaaaa;
  color: #dddddd;
}
