.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_container {
  background-color: #fff;
  border-radius: 4px;
  height: 30vh;
  width: 90vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center; /* Center the contents horizontally */
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
}

.modal_title {
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  color: #2ed365;
  margin-bottom: 20px;
}
