/* Footer styles */
footer {
  background-color: #333;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Add padding to center content */
  display: flex;
  justify-content: space-between; /* Space between address and copyright */
  align-items: center; /* Vertically center content */
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap to the next line if needed */
  gap: 20px; /* Add some space between address and social icons */
  align-items: center;
}

.address {
  flex: 1; /* Expand address section to fill available space */
  text-align: left;
}

.address h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.address p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #aaa;
}

.social-icons {
  flex: 1; /* Expand social icons section to fill available space */
  text-align: right;
}

.social-icons a {
  color: #2ed365;
  font-size: 1.5rem;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #27a957;
}

.copyright {
  margin-top: 20px;
  font-size: 1.2rem;
}
